import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { bool, func, node, string } from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import styled from 'astroturf/react';
import {
  getCountryCallingCode,
  parsePhoneNumberFromString,
  isSupportedCountry,
  parsePhoneNumberCharacter,
  AsYouType,
} from 'libphonenumber-js/core';
import {
  onChange as onChangeInputFormat,
  onKeyDown as onKeyDownInputFormat,
  onCut as onCutInputFormat,
  onPaste as onPasteInputFormat,
} from 'input-format';
import { VeloList } from '../VeloList';
import { VeloMenu } from '../VeloMenu';
import { VeloIcon } from '../VeloIcon';
import { VeloTypography } from '../VeloTypography';
import { VeloTextField } from '../VeloTextField';
import { ScrollLock } from '../ScrollLock';
import { VeloFlagIcon } from '../VeloFlagIcon';
import { dataTestIdBuilder, keyCodes } from 'velo-data';
import { countryName } from '../utils';
import { useMenuManager, useDebouncedResize } from '../hooks';
import metadata from './metadata.custom.json';

const FLAG_HEIGHT = '24px';
const FLAG_WIDTH = '32px';

const Menu = styled(VeloMenu)`
  /* Adjust to take helper text into account */
  margin-top: -1.1875rem;
`;

const FlagIcon = styled(({ icon, className }) =>
  icon ? (
    <VeloFlagIcon flag={icon} className={className} />
  ) : (
    <VeloIcon icon="public" />
  )
)`
  height: ${FLAG_HEIGHT};
  width: ${FLAG_WIDTH};
`;

const ListIcon = styled(VeloList.ItemGraphic)`
  height: ${FLAG_HEIGHT};
  width: ${FLAG_WIDTH};
`;

const CountryName = styled('span')`
  margin-right: 1rem;
`;

const CallingCode = styled(VeloList.ItemMeta)`
  &:global(.mdc-list-item__meta:not(.ds-icon)) {
    font-size: 1rem;
  }
`;

const CountryCodeContainer = styled('div')`
  display: flex;
  align-items: center;
`;

const CountryCodeLabel = styled(VeloTypography).attrs({ use: 'bodyText' })`
  @import 'velo-variables';

  color: velo-color('token-color-brand-primary');
`;

const TextField = styled(VeloTextField)`
  $padding: 120px;
  $other-padding: 72px;

  overflow: hidden;

  &:global(.mdc-text-field--with-leading-icon .mdc-text-field__input) {
    padding-left: $padding;
  }

  &:global(.mdc-text-field--with-leading-icon
      .mdc-floating-label:not(.mdc-floating-label--float-above)) {
    left: $padding;
  }

  &.other {
    &:global(.mdc-text-field--with-leading-icon .mdc-text-field__input) {
      padding-left: $other-padding;
    }

    &:global(.mdc-text-field--with-leading-icon
        .mdc-floating-label:not(.mdc-floating-label--float-above)) {
      left: $other-padding;
    }
  }

  &.floatedLabel {
    overflow: visible;
  }
`;

TextField.propTypes = {
  other: bool,
  floatedLabel: bool,
};

/**
 * The list of supported countries.
 *
 * When adding a new country to this list you need
 * to add a suitable CSS class for the flag icon
 * in `flags.scss` and update the `libphonenumber-metadata`
 * script in `package.json` to ensure the country
 * metadata is included.
 */
const countries = [
  'US',
  'CA',
  'GB',
  'FR',
  'JP',
  'DE',
  'NL',
  'IE',
  'AU',
  'CH',
  'HK',
  'KE',
  'FI',
  'EE',
  'NO',
  'DK',
  'PL',
  'AT',
  'SE',
  'BE',
  'IT',
  'LI',
  'NZ',
  'ES',
  'BG',
  'PT',
  'GR',
  'SI',
  'IS',
  'MY',
  'BR',
  'IN',
  'SG',
  'OM',
];

// Test IDs suffixes
const TestIds = {
  MENU: 'menu',
  SELECT_COUNTRY: 'select-country',
  COUNTRY_CODE: 'country-code',
};

// Render a menu containing flags, country name and dialling code
const CountryMenu = ({ open, country, onSelect, onClose, testIdPrefix }) => {
  const [mounted, onCloseMenu] = useMenuManager(open, onClose);
  const intl = useIntl();
  return mounted ? (
    <>
      <ScrollLock />
      <Menu
        open={open}
        onSelect={onSelect}
        onClose={onCloseMenu}
        fixed
        data-testid={dataTestIdBuilder(testIdPrefix, TestIds.MENU)}
      >
        {/* Supported counties in alphabetical order. */}
        {countries
          .map((code) => ({
            code,
            name: countryName(code, intl),
            callingCode: getCountryCallingCode(code, metadata),
          }))
          .sort((a, b) => a.name.localeCompare(b.name))
          .map(({ code, name, callingCode }) => (
            <VeloMenu.Item
              key={code}
              selected={country === code}
              data-country={code}
            >
              <ListIcon
                icon={{ strategy: 'component', icon: <FlagIcon icon={code} /> }}
              />
              <CountryName
                data-testid={dataTestIdBuilder(testIdPrefix, 'country', code)}
              >
                {name}
              </CountryName>
              <CallingCode>{`+${callingCode}`}</CallingCode>
            </VeloMenu.Item>
          ))}
        {/* Separator */}
        <VeloMenu.Divider />
        {/* Other */}
        <VeloMenu.Item data-country="" selected={country === ''}>
          <VeloList.ItemGraphic
            icon="public"
            theme="textSecondaryOnBackground"
          />
          <span
            data-testid={dataTestIdBuilder(testIdPrefix, 'country', 'other')}
          >
            Other
          </span>
        </VeloMenu.Item>
      </Menu>
    </>
  ) : null;
};

// Render a flag, drop-arrow and country code
const CountryCode = ({ country, ...other }) => {
  const countryCode = isSupportedCountry(country, metadata) ? country : '';
  return (
    <CountryCodeContainer>
      <FlagIcon icon={countryCode} />
      <VeloIcon icon="arrow_drop_down" />
      {countryCode && (
        <CountryCodeLabel data-testid={other['data-testid']}>
          {`+${getCountryCallingCode(countryCode, metadata)}`}
        </CountryCodeLabel>
      )}
    </CountryCodeContainer>
  );
};

VeloPhoneNumberField.propTypes = {
  /** Custom CSS class(es). */
  className: string,
  /** The component name. Used with `onChange` events. */
  name: string,
  /** The text field label. */
  label: node.isRequired,
  /** The current phone number value. */
  value: string,
  /** The default selected country. */
  defaultCountry: string.isRequired,
  /**
   * Called when the phone number is changed.
   * Passed a `target` containing `name` and
   * `value` to match a standard `input`.
   */
  onChange: func.isRequired,
};

VeloPhoneNumberField.testIds = TestIds;

/**
 * Velo Phone number entry component.
 *
 * Allows the user to select a suitable country which is used
 * when formatting the phone number. The user can type in any
 * number they wish: when it changes the `onChange` prop is
 * called and is passed the the input formatted as an
 * international (E.164) phone number.
 */
function VeloPhoneNumberField({
  className,
  name,
  label,
  value,
  defaultCountry,
  onChange,
  ...other
}) {
  const textField = useRef(null);
  const input = useRef(null);
  const [invalid, setInvalid] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [country, setCountry] = useState(() => {
    // Set the initial country
    const details = parsePhoneNumberFromString(value, metadata);
    return details ? details.country || '' : value ? '' : defaultCountry;
  });
  const [phoneNumber, setPhoneNumber] = useState(() => {
    // Set the initial phone number
    const details = parsePhoneNumberFromString(value, metadata);
    return details && details.country ? details.formatNational() : value;
  });
  const [intlPhoneNumber, setIntlPhoneNumber] = useState(() => {
    // Store the initial E164 value
    const details = parsePhoneNumberFromString(value, metadata);
    return details ? details.number : value;
  });
  const [hasFocus, setHasFocus] = useState(false);
  const intl = useIntl();

  useEffect(() => {
    // We will manually validate because of clicks
    // on the Country icon causing the underlying input
    // to lose focus,
    textField.current.foundation.setUseNativeValidation(false);
  }, []);

  // Used to sync the new `value` with the existing phone number.
  useEffect(() => {
    if (value !== intlPhoneNumber) {
      const details = parsePhoneNumberFromString(value, undefined, metadata);
      setPhoneNumber(
        details && details.country ? details.formatNational() : value
      );
      setCountry(details ? details.country || '' : value ? '' : country);
      setIntlPhoneNumber(value);
    }
  }, [value, country, intlPhoneNumber]);

  // Hooks to check for the default country changing
  const previousDefaultCountry = useRef(defaultCountry);
  useEffect(() => {
    if (previousDefaultCountry.current !== defaultCountry) {
      if (
        parsePhoneNumberFromString(value, undefined, metadata) === undefined
      ) {
        setCountry(defaultCountry);
      }
      previousDefaultCountry.current = defaultCountry;
    }
  }, [defaultCountry, value]);

  // Close the menu if the window is resized
  const onResize = useCallback(() => setMenuOpen(false), []);
  useDebouncedResize(onResize);

  const getChangeEventParams = useCallback(
    (value) => ({
      target: { name, value },
    }),
    [name]
  );

  const selectCountryTestId = dataTestIdBuilder(
    other['data-testid'],
    TestIds.SELECT_COUNTRY
  );

  const format = useCallback(
    (value) => {
      // "As you type" formatter.
      const formatter = new AsYouType(country, metadata);
      // Format the number.
      const text = formatter.input(value);
      // Return the object that `input-format` requires.
      return { text, template: formatter.getTemplate() };
    },
    [country]
  );

  const onChangeInput = useCallback(
    (value) => {
      setPhoneNumber(value);
      const details = parsePhoneNumberFromString(value, country, metadata);
      const number = (details && details.number) || value;
      setIntlPhoneNumber(number);
      onChange(getChangeEventParams(number));
    },
    [country, onChange, getChangeEventParams]
  );

  const keyboardMenuHandler = useCallback(
    (event) => {
      // Open the menu if the down arrow is pressed
      if (
        event.keyCode === keyCodes.DOWN_ARROW ||
        event.keyCode === keyCodes.UP_ARROW
      ) {
        event.preventDefault();
        setMenuOpen(true);
      }
      // Let `input-format` handle keydown events.
      onKeyDownInputFormat(
        event,
        input.current,
        parsePhoneNumberCharacter,
        format,
        onChangeInput
      );
    },
    [format, onChangeInput]
  );

  const inputHandlers = useMemo(
    () => ({
      onChange: (event) => {
        // Let `input-format` handle change events.
        onChangeInputFormat(
          event,
          input.current,
          parsePhoneNumberCharacter,
          format,
          onChangeInput
        );
      },
      onFocus: () => {
        setHasFocus(true);
      },
      onBlur: (event) => {
        // On blur check if the country icon was clicked.
        // If not then check the validity state.
        if (
          event.relatedTarget === null ||
          event.relatedTarget.getAttribute('data-testid') !==
            selectCountryTestId
        ) {
          setInvalid(!event.target.validity.valid);
        }
        setHasFocus(false);
      },
      onKeyDown: keyboardMenuHandler,
      onCut: (event) => {
        // Let `input-format` handle cut events.
        onCutInputFormat(
          event,
          input.current,
          parsePhoneNumberCharacter,
          format,
          onChangeInput
        );
      },
      onPaste: (event) => {
        // Let `input-format` handle paste events.
        onPasteInputFormat(
          event,
          input.current,
          parsePhoneNumberCharacter,
          format,
          onChangeInput
        );
      },
    }),
    [selectCountryTestId, format, keyboardMenuHandler, onChangeInput]
  );

  const countrySelectorHandlers = useMemo(
    () => ({
      onClick: () => setMenuOpen(true),
      onKeyDown: keyboardMenuHandler,
    }),
    [keyboardMenuHandler]
  );

  const menuHandlers = useMemo(
    () => ({
      onSelect: (event) => {
        const selectedCountry = event.detail.item.getAttribute('data-country');
        setCountry(selectedCountry);
        const details = parsePhoneNumberFromString(
          phoneNumber,
          selectedCountry,
          metadata
        );
        const number = (details && details.number) || phoneNumber;
        setIntlPhoneNumber(number);
        onChange(getChangeEventParams(number));
      },
      onClose: () => setMenuOpen(false),
    }),
    [getChangeEventParams, phoneNumber, onChange]
  );

  return (
    <>
      <TextField
        ref={textField}
        inputRef={(ref) => {
          input.current = ref;
        }}
        other={!isSupportedCountry(country, metadata)}
        floatedLabel={phoneNumber || hasFocus ? true : false}
        type="tel"
        name={name}
        label={label}
        value={format(phoneNumber).text}
        icon={{
          strategy: 'component',
          icon: (
            <CountryCode
              country={country}
              data-testid={dataTestIdBuilder(
                other['data-testid'],
                TestIds.COUNTRY_CODE
              )}
            />
          ),
          tabIndex: 0,
          ...countrySelectorHandlers,
          'data-testid': selectCountryTestId,
          'aria-label': intl.formatMessage({
            defaultMessage: 'Select country',
          }),
        }}
        pattern={country ? '([0-9 ()\\+\\-]){1,20}' : '^\\+[1-9][ 0-9]{1,20}$'}
        maxLength={20}
        helpText={{
          validationMsg: true,
          children: country ? (
            <FormattedMessage defaultMessage="Please enter a valid phone number" />
          ) : (
            <FormattedMessage defaultMessage="International phone numbers need to start with a +" />
          ),
        }}
        invalid={invalid}
        {...inputHandlers}
        {...other}
      />
      <VeloMenu.SurfaceAnchor>
        <CountryMenu
          open={menuOpen}
          country={country}
          testIdPrefix={other['data-testid']}
          {...menuHandlers}
        />
      </VeloMenu.SurfaceAnchor>
    </>
  );
}

export { VeloPhoneNumberField };
