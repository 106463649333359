import React from 'react';
import { bool, oneOf, string } from 'prop-types';
import styled from 'astroturf/react';
import { VeloList } from '../VeloList';
import { getCountries } from '../utils';

const Flag = styled('div')`
  @import '@material/elevation/mixins';
  @import 'flag-icon-css/sass/variables';
  @import 'flag-icon-css/sass/flag-icon-base';

  $flag-icon-css-path: '~flag-icon-css/flags';

  @include mdc-elevation(1);

  height: 24px;
  width: 32px;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;

  &.disabled {
    opacity: 0.37;
  }

  &.leading {
    margin-top: 0px;
  }

  &.small {
    height: 16px;
    width: 24px;
  }

  @mixin flag($country) {
    background-image: url(#{$flag-icon-css-path}#{$flag-icon-rect-path}/#{$country}.svg);
  }

  &.flag-AT {
    @include flag('at');
  }

  &.flag-AU {
    @include flag('au');
  }

  &.flag-BE {
    @include flag('be');
  }

  &.flag-BG {
    @include flag('bg');
  }

  &.flag-BR {
    @include flag('br');
  }

  &.flag-CA {
    @include flag('ca');
  }

  &.flag-CH {
    @include flag('ch');
  }

  &.flag-DE {
    @include flag('de');
  }

  &.flag-DK {
    @include flag('dk');
  }

  &.flag-EE {
    @include flag('ee');
  }

  &.flag-ES {
    @include flag('es');
  }

  &.flag-EU {
    @include flag('eu');
  }

  &.flag-FI {
    @include flag('fi');
  }

  &.flag-FR {
    @include flag('fr');
  }

  &.flag-GB {
    @include flag('gb');
  }

  &.flag-GR {
    @include flag('gr');
  }

  &.flag-HK {
    @include flag('hk');
  }

  &.flag-IT {
    @include flag('it');
  }

  &.flag-IE {
    @include flag('ie');
  }

  &.flag-IN {
    @include flag('in');
  }

  &.flag-IS {
    @include flag('is');
  }

  &.flag-JP {
    @include flag('jp');
  }

  &.flag-KE {
    @include flag('ke');
  }

  &.flag-LI {
    @include flag('li');
  }

  &.flag-MY {
    @include flag('my');
  }

  &.flag-MX {
    @include flag('mx');
  }

  &.flag-NL {
    @include flag('nl');
  }

  &.flag-NO {
    @include flag('no');
  }

  &.flag-NZ {
    @include flag('nz');
  }

  &.flag-OM {
    @include flag('om');
  }

  &.flag-PL {
    @include flag('pl');
  }

  &.flag-PT {
    @include flag('pt');
  }

  &.flag-SE {
    @include flag('se');
  }

  &.flag-SI {
    @include flag('si');
  }

  &.flag-SG {
    @include flag('sg');
  }

  &.flag-US {
    @include flag('us');
  }
`;

// Required for test mocks
Flag.propTypes = {
  flag: string,
  disabled: bool,
  leading: bool,
};

VeloFlagIcon.propTypes = {
  /** Custom CSS classes. */
  className: string,
  /** The HTML tag to render the icon in. */
  tag: string,
  /** The country flag to display. E.g. US, GB, etc. */
  flag: oneOf([...getCountries(), 'EU']),
  /** Display in the disabled state. */
  disabled: bool,
  /**
   * Used when rendering as a leading icon in a text field or select.
   * This prop will add extra padding to ensure the icon lines up
   * correctly.
   */
  leading: bool,
};

export function VeloFlagIcon({ tag, ...other }) {
  return <Flag as={tag} {...other} />;
}

const Graphic = styled(VeloList.ItemGraphic)`
  height: 24px;
  width: 32px;
  margin-right: 1rem;
`;

VeloFlagIcon.Graphic = ({ flag, disabled }) => (
  <Graphic
    icon={{
      strategy: 'component',
      icon: <VeloFlagIcon flag={flag} disabled={disabled} />,
    }}
  />
);
