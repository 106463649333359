import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  ProfileField,
  Custom,
  Email,
  TaxID,
} from '../FormFields/FormFieldTypes';
import { VeloPhoneNumberField } from '../VeloPhoneNumberField';
import { SelectCountry } from '../FormFields/SelectCountry';
import { VeloFieldGrid } from '../VeloFieldGrid';
import { VeloLabelledItem } from '../VeloLabelledItem';
import { getCountryCodes, getRegionNames } from '../utils';
import { address, mapValues } from './Fields';
import { FormPropTypes, AddPayeeMode } from './propTypes';

BusinessForm.propTypes = {
  /** Form prop types. */
  ...FormPropTypes,
};

/**
 * Used to capture Payee details for a Company/Employer.
 */
function BusinessForm({
  countries,
  values: { countryCode },
  values,
  readOnlyValues,
  mode,
  breakpoint,
  onChange,
  payeeCountryRules,
  ...other
}) {
  const isOnboardingMode = mode === AddPayeeMode.PAYEE_ONBOARDING;

  const sections = [
    {
      heading: <FormattedMessage defaultMessage="Company details" />,
      fields: [
        {
          ...ProfileField(
            <FormattedMessage defaultMessage="Company Name" />,
            <FormattedMessage defaultMessage="Please enter a company name" />,
            3,
            40
          ),
          name: 'name',
          required: true,
        },
        {
          ...ProfileField(
            <FormattedMessage defaultMessage="Operating Name" />,
            <FormattedMessage defaultMessage="Please enter a suitable operating name" />,
            1,
            100
          ),
          name: 'operatingName',
        },
        {
          ...TaxID(countryCode, payeeCountryRules),
          name: 'taxId',
          required: mode === AddPayeeMode.PAYEE_ONBOARDING,
        },
        ...(!isOnboardingMode
          ? [
              {
                ...Custom(
                  <FormattedMessage defaultMessage="Remote ID" />,
                  <FormattedMessage defaultMessage="Please enter a remote ID" />
                ),
                name: 'remoteId',
                required: true,
              },
            ]
          : []),
      ],
    },
    {
      heading: <FormattedMessage defaultMessage="Postal address" />,
      fields: [
        {
          name: 'countryCode',
          Component: SelectCountry,
          countries: getCountryCodes(countries),
          required: true,
          helpText: {
            validationMsg: true,
            children: (
              <FormattedMessage defaultMessage="Please select a country" />
            ),
          },
        },
        ...address(countryCode, getRegionNames(countries, countryCode)),
      ],
    },
    ...(isOnboardingMode
      ? [
          {
            heading: (
              <FormattedMessage defaultMessage="Company contact details" />
            ),
            fields: [
              {
                ...Email(),
                label: (
                  <FormattedMessage defaultMessage="Contact email address" />
                ),
                name: 'contactEmail',
                required: true,
              },
              {
                Component: VeloPhoneNumberField,
                label: (
                  <FormattedMessage defaultMessage="Company contact number (mobile)" />
                ),
                name: 'contactSmsNumber',
                required: true,
                defaultCountry: 'US',
              },
            ],
          },
        ]
      : []),
    {
      heading: (
        <FormattedMessage defaultMessage="Account administrator details" />
      ),
      fields: [
        ...(isOnboardingMode
          ? [
              {
                // Read only field
                name: 'loginEmail',
                label: <FormattedMessage defaultMessage="Login email" />,
                // Default unused initial value for `VeloFieldGrid` prop types error failing tests
                value: '',
                Component: VeloLabelledItem,
                children: readOnlyValues.loginEmail,
              },
              {
                ...ProfileField(
                  <FormattedMessage defaultMessage="First name" />,
                  <FormattedMessage defaultMessage="Please enter a your first name" />,
                  1,
                  40
                ),
                name: 'adminUserFirstName',
                required: true,
              },
              {
                ...ProfileField(
                  <FormattedMessage defaultMessage="Last name" />,
                  <FormattedMessage defaultMessage="Please enter a your last name" />,
                  1,
                  40
                ),
                name: 'adminUserLastName',
                required: true,
              },
            ]
          : [
              {
                ...Email(),
                name: 'email',
                required: true,
              },
            ]),
      ],
    },
  ];

  return (
    <VeloFieldGrid
      sections={mapValues(sections, values)}
      onChange={onChange}
      breakpoint={breakpoint}
      {...other}
    />
  );
}

export { BusinessForm };
