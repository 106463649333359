import React from 'react';
import { string } from 'prop-types';

Address.propTypes = {
  /** First line of the address. */
  line1: string.isRequired,
  /** Second line of the address. */
  line2: string,
  /** Third line of the address. */
  line3: string,
  /** Fourth line of the address. */
  line4: string,
  /** City. */
  city: string.isRequired,
  /** County/state/province/region. */
  countyOrProvince: string.isRequired,
  /** Postal code. */
  zipOrPostcode: string.isRequired,
};

/**
 * Render an address using props sourced from
 * a Velo API response.
 */
function Address(props) {
  return (
    <>
      {[
        'line1',
        'line2',
        'line3',
        'line4',
        'city',
        'countyOrProvince',
        'zipOrPostcode',
      ].map((name, index) => (
        <div key={index}>{props[name]}</div>
      ))}
    </>
  );
}

export { Address };
