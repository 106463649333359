import { parse, unparse } from 'papaparse';
import {
  formatCallbackErrorArg,
  selectIdFromLocation,
  withErrorDataHandler,
} from 'velo-portal-common';
import { isCSVFileValid } from '../../hooks/csv';

const csvUploadHeaders = [
  'type',
  'remoteId',
  'email',
  'addressLine1',
  'addressLine2',
  'addressLine3',
  'addressLine4',
  'addressCity',
  'addressCountyOrProvince',
  'addressZipOrPostcode',
  'addressCountry',
  'individualNationalIdentification',
  'individualDateOfBirth',
  'individualTitle',
  'individualFirstName',
  'individualOtherNames',
  'individualLastName',
  'companyName',
  'companyEIN',
  'companyOperatingName',
  'paymentChannelIban',
  'paymentChannelAccountNumber',
  'paymentChannelRoutingNumber',
  'paymentChannelAccountName',
  'paymentChannelCountryCode',
  'paymentChannelCurrency',
  'challengeDescription',
  'challengeValue',
  'payeeLanguage',
];

const csvErrorReportHeaders = ['failureReason', ...csvUploadHeaders];
const csvUploadHeadersString = csvUploadHeaders.join(',');

// Build the POST data for creating a payee
const formPostData = (payorId, data) => ({
  payorId,
  payees: [
    {
      type: data.type,
      // Individual fields
      ...(data.type === 'Individual' && {
        individual: {
          name: {
            title: data.title || null,
            firstName: data.firstName,
            otherNames: data.otherNames || null,
            lastName: data.lastName,
          },
          nationalIdentification: data.nationalIdentification || null,
          dateOfBirth: data.dateOfBirth,
        },
      }),
      // Company fields
      ...(data.type === 'Company' && {
        company: {
          name: data.name,
          operatingName: data.operatingName || null,
          taxId: data.taxId || null,
        },
      }),
      // Shared fields
      remoteId: data.remoteId,
      email: data.email,
      countryCode: data.countryCode,
      // Address fields
      address: {
        line1: data.line1,
        line2: data.line2 || null,
        line3: data.line3 || null,
        line4: data.line4 || null,
        city: data.city,
        countyOrProvince: data.countyOrProvince,
        zipOrPostcode: data.zipOrPostcode,
        country: data.countryCode,
      },
      // Challenge code
      ...(data.challengeDescription &&
        data.challengeValue && {
          challenge: {
            value: data.challengeValue,
            description: data.challengeDescription,
          },
        }),
      // Payment channel fields
      ...(data.accountName && {
        paymentChannel: {
          countryCode: data.paymentCountry,
          paymentChannelName: data.paymentChannelName,
          currency: data.currency,
          accountName: data.accountName,
          accountNumber: data.accountNumber || null,
          routingNumber: data.routingNumber || null,
          iban: data.iban || null,
        },
      }),
    },
  ],
});

const mapRejectedPayees = (failures) => {
  const rejectionRows = failures.map(
    ({ failedSubmission, failureMessage, rejectedContent, message }) => {
      if (failedSubmission) {
        const address = failedSubmission.address || {};
        const individual = failedSubmission.individual || {};
        const individualName = individual.name || {};
        const company = failedSubmission.company || {};
        const paymentChannel = failedSubmission.paymentChannel || {};
        const challenge = failedSubmission.challenge || {};

        return {
          failureReason: failureMessage,
          type: failedSubmission.type,
          remoteId: failedSubmission.remoteId,
          email: failedSubmission.email,
          addressLine1: address.line1,
          addressLine2: address.line2,
          addressLine3: address.line3,
          addressLine4: address.line4,
          addressCity: address.city,
          addressCountyOrProvince: address.countyOrProvince,
          addressZipOrPostcode: address.zipOrPostcode,
          addressCountry: address.country,
          individualNationalIdentification: individual.nationalIdentification,
          individualDateOfBirth: individual.dateOfBirth,
          individualTitle: individualName.title,
          individualFirstName: individualName.firstName,
          individualOtherNames: individualName.otherNames,
          individualLastName: individualName.lastName,
          companyName: company.name,
          companyEIN: company.taxId,
          companyOperatingName: company.operatingName,
          paymentChannelIban: paymentChannel.iban,
          paymentChannelAccountNumber: paymentChannel.accountNumber,
          paymentChannelRoutingNumber: paymentChannel.routingNumber,
          paymentChannelAccountName: paymentChannel.accountName,
          paymentChannelCountryCode: paymentChannel.countryCode,
          paymentChannelCurrency: paymentChannel.currency,
          challengeDescription: challenge.description,
          challengeValue: challenge.value,
          payeeLanguage: failedSubmission.language,
        };
      } else {
        /**
         * Parse the `rejectedContent` strings so that it can be converted back into CSV
         * in the same way as the list of `failedSubmission` objects.
         */
        const rejectedCsvContent = `${csvUploadHeadersString}
${rejectedContent}`;

        return {
          failureReason: message,
          ...parse(rejectedCsvContent, { header: true }).data[0],
        };
      }
    }
  );

  return unparse({ fields: csvErrorReportHeaders, data: rejectionRows });
};

export function PayorCreatePayeePresenter(
  wireframe,
  entity,
  payorId,
  notification,
  downloadContentAsFile,
  setReadFailures,
  intl,
  reader = new FileReader()
) {
  function onComplete(failureCount, successCount) {
    if (failureCount > 0) {
      wireframe.navigateToPayeeCreate.redirect();
    } else {
      wireframe.backToRootPath();
    }
    if (successCount > 0) {
      wireframe.sendNote({
        ...notification,
        message: intl.formatMessage(notification.message, {
          itemCount: successCount,
        }),
        key: Date.now().toString(10),
      });
    }
  }
  function onClose() {
    wireframe.backToRootPath();
  }

  // Note Submission is just the posting of the data
  // - the success of posting is determined by polling the batch
  function onSubmitSuccess(result, batchCount) {
    setReadFailures(result.rejectedCsvRows || []);
    const batchId = selectIdFromLocation(result.location);
    wireframe.navigateToPayeeUpload.redirect({ batchId, batchCount });
  }

  function submitForm(data, cb) {
    return entity.createPayee(data, 'application/json', (error, result) => {
      if (!error) {
        onSubmitSuccess(result, 1);
      } else {
        cb(error);
      }
    });
  }

  function getBatchStatus(data, cb) {
    return entity.batchStatus(
      data,
      formatCallbackErrorArg((error, result) => {
        if (!error) {
          cb(undefined, result);
        } else {
          cb(error);
        }
      })
    );
  }

  function readFile(file, cb) {
    reader.onload = (event) => {
      const csvStringLines = event.currentTarget.result.split(/\r?\n|\r/);
      let csvLinesCount = csvStringLines.length;
      if (csvStringLines[csvStringLines.length - 1] === '') {
        csvLinesCount = csvStringLines.length - 2;
      } else {
        csvLinesCount = csvStringLines.length - 1;
      }

      //only interested in batchCount
      cb(null, csvLinesCount);
    };

    reader.onerror = (event) => cb(event.currentTarget.error);
    reader.readAsText(file);
  }
  function submitFile(data, cb) {
    // Only reading file to determine total submitted - batch API (see processing route)
    // do not provide a TOTAL on which to calculate success and failure payee entities created
    readFile(data, (error, batchCount) => {
      if (!error) {
        return entity.createPayee(
          { payorId: payorId, file: data },
          'multipart/form-data',
          withErrorDataHandler(
            formatCallbackErrorArg((error, result) =>
              error ? cb(error) : onSubmitSuccess(result, batchCount)
            ),
            {
              onErrorData: (errorData) => {
                setReadFailures(errorData.content);
                cb();
              },
            }
          )
        );
      } else {
        // return the read error
        cb(error);
      }
    });
  }
  // TODO: Localisation of these UI error strings
  function onSubmit(data, cb) {
    if (data.length) {
      if (data.length === 1) {
        if (isCSVFileValid(data[0])) {
          return submitFile(data[0], cb);
        } else {
          return cb(
            'The file you are trying to upload is invalid. Only a valid .csv can be accepted.'
          );
        }
      } else {
        return cb(
          'Multiple files detected. Please upload 1 CSV file at a time.'
        );
      }
    } else {
      return submitForm(formPostData(payorId, data), cb);
    }
  }

  return {
    onComplete,
    onClose,
    onSubmit,
    getBatchStatus,
    downloadSampleCsv: () => {
      const payeeSampleCsv = csvUploadHeaders.join(',');
      downloadContentAsFile(
        payeeSampleCsv,
        'Group_Payee_Sample_CSV.csv',
        'text/csv;encoding:utf-8'
      );
    },
    downloadErrors: (failuresList) => {
      const csvContent = mapRejectedPayees(failuresList);
      downloadContentAsFile(
        csvContent,
        'Payee_Errors.csv',
        'text/csv;encoding:utf-8'
      );
    },
  };
}
