import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  ProfileField,
  Custom,
  Email,
  NationalIdentification,
} from '../FormFields/FormFieldTypes';
import { SelectCountry } from '../FormFields/SelectCountry';
import { VeloFieldGrid } from '../VeloFieldGrid';
import { VeloDatePicker } from '../VeloDatePicker';
import { getCountryCodes, getRegionNames } from '../utils';
import { address, mapValues } from './Fields';
import { FormPropTypes, AddPayeeMode } from './propTypes';

PersonForm.propTypes = {
  /** Form prop types. */
  ...FormPropTypes,
};

/**
 * Used to capture Payee details for an individual.
 */
function PersonForm({
  countries,
  values: { countryCode },
  values,
  mode,
  breakpoint,
  onChange,
  payeeCountryRules,
  readOnlyValues,
  ...other
}) {
  const sections = [
    {
      heading: <FormattedMessage defaultMessage="Name" />,
      fields: [
        {
          ...ProfileField(
            <FormattedMessage defaultMessage="Title" />,
            <FormattedMessage defaultMessage="Please enter a title" />,
            1,
            10
          ),
          name: 'title',
          desktop: 2,
        },
        {
          ...ProfileField(
            <FormattedMessage defaultMessage="First Name" />,
            <FormattedMessage defaultMessage="Please enter a first name" />,
            1,
            50
          ),
          name: 'firstName',
          required: true,
          desktop: 10,
        },
        {
          ...ProfileField(
            <FormattedMessage defaultMessage="Middle/Other Name" />,
            <FormattedMessage defaultMessage="Please enter a middle/other name" />,
            1,
            50
          ),
          name: 'otherNames',
        },
        {
          ...ProfileField(
            <FormattedMessage defaultMessage="Last Name" />,
            <FormattedMessage defaultMessage="Please enter a last name" />,
            1,
            50
          ),
          name: 'lastName',
          required: true,
        },
      ],
    },
    {
      heading: <FormattedMessage defaultMessage="Postal address" />,
      fields: [
        {
          name: 'countryCode',
          Component: SelectCountry,
          helpText: {
            validationMsg: true,
            children: (
              <FormattedMessage defaultMessage="Please select a country" />
            ),
          },
          countries: getCountryCodes(countries),
          required: true,
        },
        ...address(countryCode, getRegionNames(countries, countryCode)),
      ],
    },
    {
      heading: <FormattedMessage defaultMessage="Other details" />,
      fields: [
        ...(mode === AddPayeeMode.PAYOR_CREATE ||
        mode === AddPayeeMode.PAYOR_EDIT
          ? [
              {
                ...Email(),
                name: 'email',
                required: true,
              },
            ]
          : []),
        {
          name: 'dateOfBirth',
          Component: VeloDatePicker,
          label: <FormattedMessage defaultMessage="Date of Birth" />,
          helpText: {
            validationMsg: true,
            children: (
              <FormattedMessage defaultMessage="Please enter a valid date of birth" />
            ),
          },
          required: true,
        },
        {
          name: 'nationalIdentification',
          ...NationalIdentification(countryCode, payeeCountryRules),
          autoComplete: 'new-password',
          // This remounts the component when the label
          // changes to ensure it is not clipped.
          key: countryCode,
          required: mode === AddPayeeMode.PAYEE_ONBOARDING,
        },
        ...(mode === AddPayeeMode.PAYOR_CREATE ||
        mode === AddPayeeMode.PAYOR_EDIT
          ? [
              {
                ...Custom(
                  <FormattedMessage defaultMessage="Remote ID" />,
                  <FormattedMessage defaultMessage="Please enter a remote ID" />
                ),
                name: 'remoteId',
                required: true,
              },
            ]
          : []),
      ],
    },
  ];

  return (
    <VeloFieldGrid
      sections={mapValues(sections, values)}
      onChange={onChange}
      breakpoint={breakpoint}
      {...other}
    />
  );
}

export { PersonForm };
